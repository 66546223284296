<script>
import ToggleButton from '../../../components/Common/ToggleButton.vue';

export default {
  components: { ToggleButton },
  name: 'notifications_settings',

  data() { return {
    settingsTable: {},
  }},

  created() 
  {
    this.RequestSettingsData();
  },

  methods: {
    RequestSettingsData()
    {
      this.$api.get( { path: `/user/notifications/settings` }, ({response}) => {
        this.settingsTable = response.data;
      });
    },

    Toggle(key)
    {
      this.$api.patch({ path: `/user/notifications/settings/${key}` }, () => {
        this.settingsTable[key] = !this.settingsTable[key];
      });
    }
  }
};
</script>


<template>
  <div class="n-list">
    <div @click.self="Toggle(key)" v-for="(value, key) in settingsTable" :key="key" class="config-row row">
      <toggle-button @toggle="Toggle(key)" :toggled="Boolean(value)">
        {{$lan(`${key}_settings`)}}
      </toggle-button>
    </div>
  </div>
</template>

<style scoped>
.n-list {
  flex-grow: 1;
  /* width: 100%; */
  display: flex;
  flex-direction: column;
}

.row {
  gap: 10px;
  padding-left: 10px;
}
</style>

